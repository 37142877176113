/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button } from 'components/atoms/Button'

export const columns = [
  {
    customWidth: 235,
    Header: () => 'Package Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row.original.name}</div>,
  },
  {
    customWidth: 242,
    Header: () => 'Type',
    accessor: 'type',
    Cell: ({ row }) => <div>{row.original.type}</div>,
  },
  {
    customWidth: 100,
    Header: 'Qty',
    accessor: 'qty',
    Cell: ({ row }) => <div>{row.original.qty}</div>,
  },
  {
    customWidth: 150,
    Header: 'Pickup Date',
    accessor: 'pickupdate',
    Cell: ({ row }) => <div>{row.original.pickupDate?.split(`T`)[0]}</div>,
  },
  {
    customWidth: 150,
    Header: 'Consigned Date',
    accessor: 'consigneddate',
    Cell: ({ row }) => <div>{row.original.createdOn?.split(`T`)[0]}</div>,
  },
  {
    customWidth: 150,
    Header: 'Tracking Code',
    accessor: 'trackingCode',
    Cell: ({ row }) => (
      <div
        onClick={() => {
          ;(row.original.consignedType === 'AUSPOST' ||
            row.original.consignedType === 'AUSPOST_INTERNATIONAL') &&
            window.open(
              `https://auspost.com.au/mypost/track/#/details/${row.original.trackingCode}`,
              '_blank',
            )
          row.original.consignedType === 'DIRECT' &&
            window.open(
              `https://www.directfreight.com.au/ConsignmentStatus.aspx?lookuptype=0&consignment_no=${row.original.trackingCode}`,
              '_blank',
            )
          row.original.consignedType === 'SMART/SEND' &&
            window.open(`https://www.smartsend.com.au/tracking.cfm`, '_blank')
        }}
        className='text-sm font-semibold text-P-500'>
        {row.original.trackingCode}
      </div>
    ),
  },
  {
    customWidth: 218,
    Header: 'Article ID',
    accessor: 'articleId',
    Cell: ({ row }) => (
      <div className='text-sm font-semibold text-P-500'>
        {row.original.articleId}
      </div>
    ),
  },
  {
    customWidth: 249,
    Header: '',
    accessor: 'labelUrl',
    Cell: ({ row }) => (
      <div>
        <Button
          className='w-36'
          disabled={row.original.type === 'DSE'}
          appearance='dull'
          size='sm'
          onClick={() => {
            window.open(row.original.labelUrl, '_blank')
          }}>
          Print Label
        </Button>
      </div>
    ),
  },
]

export default columns
