import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

export const getPartnerAllList = async () => {
  const res = await get(`PartnerAll`)
  return res.data
}
export const useGetPartnerAllList = () => {
  return useQuery(['reqData-partnerall'], getPartnerAllList, {
    refetchOnWindowFocus: true,
  })
}
export default useGetPartnerAllList
